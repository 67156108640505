.nav-container {
  position: fixed;
  top: 20px;
  left: 3%;
  right: 3%;
  width: 94%;
  padding: 14px 2%;
  background: #fff;
  /* box-shadow: -15px 15px 15px rgb(0, 0, 0, 0.2); */
  /* border: 0.2px solid rgb(0, 0, 0, 0.2); */
  border-radius: 28px;
  box-shadow: 0px 0px 5px 3px rgba(84, 83, 83, 0.04);
  -webkit-box-shadow: 0px 0px 5px 3px rgba(84, 83, 83, 0.04);
  -moz-box-shadow: 0px 0px 5px 3px rgba(84, 83, 83, 0.04);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  isolation: auto;
  /* mix-blend-mode: multiply; */
  /* backdrop-filter: blur(7px); */
  /* border-top: 0.1rem solid #f49038;
  border-bottom: 0.1rem solid #f49038; */
}

.logo {
  display: inline-flex;
  width: 155px;
  height: 28px;
  background: transparent;
  justify-content: center;
  align-items: center;

  opacity: 0;
  animation: slideRight 1s ease forwards;
}

.nav {
  /* display: flex;
   text-align: left;
   margin-top: 2rem; */
  transition: 0.3s;
  opacity: 0;
  animation: slideTop 0.75s ease forwards;

  & .menu {
    list-style: none; /* Removes bullet points */
    padding: 0; /* Removes padding */
    margin: 0;
    color: #424242;
    display: flex;
    align-items: center;
    gap: 1.8rem;
    font-weight: 500;
    font-size: 14px;
    position: relative;

    & li {
      letter-spacing: 0.2px;
      cursor: pointer;
      position: relative;
      padding: 5px 0; /* Add space for the border */
      transition: color 0.3s ease-in-out;
      /* background: linear-gradient(45deg, #002365, #499c04); */

      & a {
        text-decoration: none; /* or any default styles you want */
        color: inherit; /* matches the text color */
      }

      /* Disable hover styles */
      & a:hover {
        text-decoration: none;
        color: inherit; /* Same color as the default */
        cursor: default; /* Optional: prevent pointer cursor */
      }
    }
  }
}
.menu li.active::after,
.menu li:hover::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px; /* Border thickness */
  background-color: black;
  transition: all 0.3s ease-in-out; /* Smooth transition for border movement */
}

.menu li::after {
  content: "";
  position: absolute;
  left: 0;
  right: 100%; /* Start from fully hidden */
  bottom: 0;
  height: 2px;
  background-color: black;
  transition: all 0.3s ease-in-out; /* Transition on hover or active state */
}

.menu li.active::after {
  right: 0; /* Fully visible when active */
}

.menu li:hover {
  color: #000; /* Optional: Darken color on hover */
}
.nav a {
  display: inline-block;
  font-size: 18px;
  color: #222222;
  text-decoration: none;
  font-weight: 500;
  margin: 0 15px;
  cursor: pointer;
  /* transition: 0.3s;
  opacity: 0;
  animation: slideTop 0.75s ease forwards; */
}

/* .nav a.active, */

.nav a:hover {
  color: #1743e3;
  /* animation: shake .5s; */
  /* animation: shake .5s cubic-bezier(.36, .07, .19, .97) both; */
}

.nav .dropdown {
  display: inline-block;
}

.nav .dropdown .dropbtn {
  font-size: 18px;
  color: #222222;
  text-decoration: none;
  font-weight: 500;
  margin: 0 15px;
  border: none;
  outline: none;
  /* padding: 14px 16px; */
  background-color: inherit;
  font-family: inherit;
  /* transition: 0.3s;
  opacity: 0;
  animation: slideTop 0.75s ease forwards; */
}

.dropdown-content {
  display: none;
  position: absolute;
  margin-top: 1rem;
  background-color: #ffffff;
  min-width: 160px;
  border: 0.1rem solid #f49038;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  z-index: 110;
  /* isolation: auto;
  mix-blend-mode: multiply;
  backdrop-filter: blur(7px); */
}

.dropdown-content:after {
  content: " ";
  position: absolute;
  right: 80px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #f49038;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.dropdown:hover .dropdown-content {
  display: block;
  opacity: 1;
}

.nav-container-sec {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  padding: 14px 4%;
  background: #ffffff;
  box-shadow: -15px 15px 15px rgb(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  isolation: auto;
  border-top: 0.1rem solid #cccccc;
  /* mix-blend-mode: multiply; */
  /* backdrop-filter: blur(7px); */
  /* border-top: 0.1rem solid #f49038;
  border-bottom: 0.1rem solid #f49038; */
}

.dropdown-content-serv {
  display: none;
  position: absolute;
  margin-top: 1rem;
  background-color: #ffffff;
  min-width: 160px;
  border: 0.1rem solid #f49038;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  z-index: 110;
  /* isolation: auto;
  mix-blend-mode: multiply;
  backdrop-filter: blur(7px); */
}

.dropdown-content-serv:after {
  content: " ";
  position: absolute;
  left: 40px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #f49038;
}

/* Links inside the dropdown */
.dropdown-content-serv a {
  float: none;
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.dropdown:hover .dropdown-content-serv {
  display: block;
  opacity: 1;
}

@keyframes slideRight {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideTop {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0);
  }

  15% {
    transform: rotate(5deg);
  }

  30% {
    transform: rotate(-5deg);
  }

  45% {
    transform: rotate(4deg);
  }

  60% {
    transform: rotate(-4deg);
  }

  75% {
    transform: rotate(2deg);
  }

  85% {
    transform: rotate(-2deg);
  }

  92% {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(0);
  }
}

@media only screen and (max-width: 1030px) {
  .nav .dropdown .dropbtn {
    font-size: 14px;
  }

  .nav a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 780px) {
  .logo {
    width: 4.5rem;
    height: 2.5rem;
  }

  .nav .dropdown .dropbtn {
    font-size: 10px;
  }

  .nav a {
    font-size: 10px;
  }
}

@media only screen and (max-width: 385px) {
  .nav {
    display: none;
  }
}

/* @media screen and (max-width: 320px) {
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', serif;
    font-family: 'Inter', serif;
  }

  #logo {
    display: none;
  }
} */
