.UnderConst {
  position: relative;
  width: 100%;
  height: auto;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  padding: 4rem 4%;
  overflow: hidden;
  scroll-behavior: smooth;
}

.construct {
  padding: 4rem 0;
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

/* .service {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: 6rem 4% 0;
} */

.construct-content {
  max-width: 730px;
}

.construct-content h1 {
  font-size: 50px;
  line-height: 1.2;
  /* color: #002365; */
  color: #222222;
  /* text-transform: uppercase; */
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.construct-content h3 {
  font-size: 30px;
  /* color: #667ba3; */
  color: #222222;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
}

.construct-content p {
  font-size: 16px;
  margin: 15px 0 30px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}
