@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* -------------- General Information -------------- */
/* General backround colour: #fafafa
 Rhombus background colour: #eaeaea border:25px solid #667ba3
 h3 colour: #667ba3/ #3c3b3d font-size: 30px
 h2 colour: #9e447c font-size: 40px
 h1 colour: #002365 font-size: 50px
 Normal text font-size: 16px
 Header background colour: #ffffff (this will be changed due to mix-blend-mode: multiply;)
 Header colour: #222222 hover-colour: #1743e3 font-size: 18px
 Footer content colour: #fafafa font-size: 14px hover-colour: #f49038
 Footer background-color: #002365;
 */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Roboto', sans-serif; */
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

.App {
  background: #fafafa;
  height: 100vh;
  scroll-behavior: smooth;
}

/* .banner {
  background: url("./assets/banner.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
} */

/* .banner02 {
  background: url("./assets/banner02.svg");
  background-color: #0b65c6;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
} */

/* .banner03 {
  background: url("./assets/banner03.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
} */

.banner04 {
  background: url("./assets/banner04.svg");
  background-color: #fafafa;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

/* Scroll bar css */
::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  background: #f6f6f6;
}

*::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 20px;
  border: 3px solid #f6f6f6;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.gradient-button {
  position: relative;
  padding: 10px 18px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  background: transparent;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  transition: color 0.5s ease-in-out, background 0.5s ease-in-out; /* Add background transition */
  z-index: 1;
}
.gradient-button.white {
  color: #fff;
}
.gradient-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 25px;
  padding: 2px; /* Adjust border thickness */
  background: linear-gradient(to right, rgb(33, 102, 229), rgb(72, 156, 4));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.gradient-button:hover {
  background: linear-gradient(
    to right,
    rgba(33, 102, 229, 0.7),
    rgba(72, 156, 4, 0.7)
  ); /* Adds background on hover */
  color: #fff;
}
