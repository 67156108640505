/* `````````````````````````` */
/* Testimonials */
/* `````````````````````````` */

.section-testimonials {
  background-color: #eaeaea;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  /* border-top: 0.5rem solid #f49038;
  border-bottom: 0.5rem solid #f49038; */
}

.testimonials-container {
  padding: 8.6rem;
}

/* .subheading {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #cf711f;
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  letter-spacing: 0.75px;
} */

.testimonials-container .subheading {
  font-size: 50px;
  line-height: 1.2;
  /* color: #002365; */
  color: #222222;
  text-transform: uppercase;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.testimonials-container .heading-secondary {
  font-size: 30px;
  /* color: #667ba3; */
  color: #222222;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
}

.testimonials {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 4.8rem;
  column-gap: 8rem;
  margin: 4rem 0;
}

.testimonial-img {
  width: 6.4rem;
  border-radius: 50%;
  margin-bottom: 1.2rem;
}

.testimonial-text {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 1.6rem;
}

.testimonial-name {
  font-size: 18px;
  color: #6f6f6f;
}

.gallery {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6rem;
  padding: 1.6rem;
}

.gallery-item {
  overflow: hidden;
}

.gallery-item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* background-size: contain; */
  transition: all 0.4s;
}

.gallery-item img:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 1030px) {
  .testimonials-container {
    padding: 4.3rem;
  }

  .testimonials-container .subheading {
    font-size: 40px;
    line-height: 1;
  }

  .testimonials-container .heading-secondary {
    font-size: 20px;
  }

  .testimonials {
    row-gap: 2.4rem;
    column-gap: 4rem;
  }

  .testimonial-text {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 1.4rem;
  }
}

@media only screen and (max-width: 780px) {
  .testimonials-container {
    padding: 2.3rem;
  }

  .testimonials-container .subheading {
    font-size: 30px;
    line-height: 1;
  }

  .testimonials-container .heading-secondary {
    font-size: 15px;
  }

  .testimonials {
    row-gap: 1.4rem;
    column-gap: 2rem;
    margin: 2rem 0;
  }

  .testimonial-img {
    width: 3.2rem;
  }

  .testimonial-text {
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 1.2rem;
  }

  .testimonial-name {
    font-size: 12px;
  }
}

@media only screen and (max-width:385px) {
  .section-testimonials {
    display: block;
  }

  .testimonials-container .subheading {
    font-size: 20px;
    line-height: 1;
  }

  .testimonials-container .heading-secondary {
    font-size: 12px;
  }

  .testimonials {
    row-gap: 1.2rem;
    column-gap: 1.5rem;
    margin: 1.5rem 0;
  }

  .testimonial-img {
    width: 2.8rem;
  }

  .testimonial-text {
    font-size: 10px;
    line-height: 1;
    margin-bottom: 1rem;
  }

  .testimonial-name {
    font-size: 10px;
  }

  .gallery {
    display: none;
  }
}