.nav-container-DataEnggAna {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 14px 4%;
  background: #ffffff;
  box-shadow: -15px 15px 15px rgb(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  isolation: auto;
  /* mix-blend-mode: multiply; */
  /* backdrop-filter: blur(7px); */
  /* border-top: 0.1rem solid #f49038;
  border-bottom: 0.1rem solid #f49038; */
}

.logo-DataEnggAna {
  display: inline-flex;
  width: 9rem;
  height: 5rem;
  background: transparent;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  opacity: 0;
  animation: slideRight 1s ease forwards;
}

.nav-DataEnggAna {
  /* display: flex;
   text-align: left;
   margin-top: 2rem; */
  transition: 0.3s;
  opacity: 0;
  animation: slideTop 0.75s ease forwards;
}

.nav-DataEnggAna a {
  display: inline-block;
  font-size: 18px;
  color: #222222;
  text-decoration: none;
  font-weight: 500;
  margin: 0 15px;
  cursor: pointer;
  /* transition: 0.3s;
  opacity: 0;
  animation: slideTop 0.75s ease forwards; */
}

/* .nav-DataEnggAna a.active, */

.nav-DataEnggAna a:hover {
  color: #1743e3;
  animation: shake .5s;
  /* animation: shake .5s cubic-bezier(.36, .07, .19, .97) both; */
}

.nav-DataEnggAna .dropdown-DataEnggAna {
  display: inline-block;
}

.nav-DataEnggAna .dropdown-DataEnggAna .dropbtn-DataEnggAna {
  font-size: 18px;
  color: #222222;
  text-decoration: none;
  font-weight: 500;
  margin: 0 15px;
  border: none;
  outline: none;
  /* padding: 14px 16px; */
  background-color: inherit;
  font-family: inherit;
  /* transition: 0.3s;
  opacity: 0;
  animation: slideTop 0.75s ease forwards; */
}

.dropdown-content-DataEnggAna {
  display: none;
  position: absolute;
  margin-top: 1rem;
  background-color: #ffffff;
  min-width: 160px;
  border: 0.1rem solid #f49038;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  z-index: 110;
  /* isolation: auto;
  mix-blend-mode: multiply;
  backdrop-filter: blur(7px); */
}

.dropdown-content-DataEnggAna:after {
  content: " ";
  position: absolute;
  right: 80px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #f49038;
}

/* Links inside the dropdown */
.dropdown-content-DataEnggAna a {
  float: none;
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.dropdown-DataEnggAna:hover .dropdown-content-DataEnggAna {
  display: block;
  opacity: 1;
}

.dropdown-content-serv-DataEnggAna {
  display: none;
  position: absolute;
  margin-top: 1rem;
  background-color: #ffffff;
  min-width: 160px;
  border: 0.1rem solid #f49038;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  z-index: 110;
  /* isolation: auto;
  mix-blend-mode: multiply;
  backdrop-filter: blur(7px); */
}

.dropdown-content-serv-DataEnggAna:after {
  content: " ";
  position: absolute;
  left: 40px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #f49038;
}

/* Links inside the dropdown */
.dropdown-content-serv-DataEnggAna a {
  float: none;
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.dropdown-DataEnggAna:hover .dropdown-content-serv-DataEnggAna {
  display: block;
  opacity: 1;
}

@keyframes slideRight {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideTop {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0);
  }

  15% {
    transform: rotate(5deg);
  }

  30% {
    transform: rotate(-5deg);
  }

  45% {
    transform: rotate(4deg);
  }

  60% {
    transform: rotate(-4deg);
  }

  75% {
    transform: rotate(2deg);
  }

  85% {
    transform: rotate(-2deg);
  }

  92% {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(0);
  }
}

@media only screen and (max-width:1030px) {
  .nav-DataEnggAna .dropdown-DataEnggAna .dropbtn-DataEnggAna {
    font-size: 14px;
  }

  .nav-DataEnggAna a {
    font-size: 14px;
  }
}

@media only screen and (max-width:780px) {
  .logo-DataEnggAna {
    width: 4.5rem;
    height: 2.5rem;
  }

  .nav-DataEnggAna .dropdown-DataEnggAna .dropbtn-DataEnggAna {
    font-size: 10px;
  }

  .nav-DataEnggAna a {
    font-size: 10px;
  }
}

@media only screen and (max-width:385px) {
  .nav-DataEnggAna {
    display: none;
  }
}

/* @media screen and (max-width: 320px) {
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', serif;
    font-family: 'Inter', serif;
  }

  #logo {
    display: none;
  }
} */