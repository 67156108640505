.dea {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr;
  gap: 1.4rem; */
  padding: 8rem 4% 8%;
  overflow: hidden;
  /* background-image: url("../../assets/gleafinkBackground1.jpg"); */
  background-color: #ffffff;
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  box-shadow: 0 0 4px 4px white inset;
  border-radius: 8px;
}

.dea-content {
  /* max-width: 630px; */
  /* height: 25rem; */
  height: 100%;
  width: 100%;
  text-align: center;
  /* padding: 8rem 4% 8rem; */
}

.dea-content-flex {
  /* max-width: 630px; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18rem;
  width: 100%;
  text-align: center;
  gap: 1.4rem;
  /* padding: 8rem 4% 8rem; */
  margin: 4rem 4%;
}

.dea-content-grid {
  /* max-width: 630px; */
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  height: auto;
  width: 100%;
  text-align: center;
  gap: 1.4rem;
  /* padding: 8rem 4% 8rem; */
  margin: 4rem 4%;
}

.dea-content h1,
.dea-h1 {
  font-size: 50px;
  line-height: 1.2;
  text-align: center;
  /* color: #002365; */
  color: #222222;
  margin: 4rem 0 2rem;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.dea-content h3 {
  font-size: 30px;
  /* color: #667ba3; */
  color: #222222;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
}

.dea-content p {
  font-size: 16px;
  margin: 15px 0 30px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}

.dea-points {
  margin-top: 2rem;
}


.dea-points h3 {
  font-size: 40px;
  /* color: #667ba3; */
  color: #222222;
}

.dea-points p {
  font-size: 16px;
  margin: 15px 0 30px;
}

.dea-img {
  position: relative;
  right: -7%;
  width: 450px;
  height: 450px;
  transform: rotate(45deg);
}

.dea-img .rhombus {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #eaeaea;
  border: 25px solid #667ba3;
  box-shadow: -15px 15px 15px rgb(0, 0, 0, 0.2);
  opacity: 0;
  animation: zoomOut 1s ease forwards;
  animation-delay: 1.75s;
}

.dea-img .rhombus img {
  position: absolute;
  top: 110px;
  left: -250px;
  max-width: 750px;
  transform: rotate(-45deg);
  /* opacity: 0;
  animation: boxImage 1s ease forwards;
  animation-delay: 2s; */
}

.dea .rhombus2 {
  position: absolute;
  top: -25%;
  right: -25%;
  width: 700px;
  height: 700px;
  background: #667ba3;
  transform: rotate(45deg);
  z-index: -1;
  /* check why this is making box dissappear */
  opacity: 0;
  animation: rhombus2 1s ease forwards;
  /* animation-delay: 1.75s; */
}

.dea-card {
  /* Add shadows to create the "card" effect */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
  height: 100%;
  /* height: auto; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 9px;
  background-color: #ffffff;
}

/* On mouse-over, add a deeper shadow */
.dea-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.0125);
}

.dea-container {
  padding: 16px 16px;
}

.dea-card-img {
  height: 5rem;
  width: 5rem;
  border-radius: 18px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.dea-card:hover .dea-card-img {
  /* animation: refresh 4s cubic-bezier(.36, .07, .57, .99) infinite; */
  animation: refresh 4s cubic-bezier(.36, .07, .57, .99);
}

.dea-btn {
  display: inline-block;
  float: left;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 16px;
  border-radius: 9px;
  cursor: pointer;
  font-family: inherit;
  background-color: #1890ff;
  color: #fafafa;
  border: 3px solid #1890ff;
  margin: 15px 0 15px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}

@keyframes slideBottom {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes rhombus2 {
  0% {
    right: -40px;
    opacity: 0;
  }

  100% {
    right: -25px;
    opacity: 1;
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes boxImage {
  0% {
    transform: translateX(300px, -300px) scale(0) rotate(-45deg);
    opacity: 0;
  }

  100% {
    transform: translateX(0, 0) scale(1) rotate(-45deg);
    opacity: 1;
  }
}

@keyframes refresh {
  from {
    transform: scale(0.9375) rotate(0);
  }

  to {
    transform: scale(0.9375) rotate(360deg);
  }
}

@media only screen and (max-width: 1030px) {
  .dea-content {
    /* max-width: 600px; */
  }

  .dea-content h1,
  .dea-h1 {
    font-size: 40px;
    line-height: 1;
  }

  .dea-content h3 {
    font-size: 20px;
  }

  .dea-content p {
    font-size: 14px;
    line-height: 1.4;
  }
}

@media only screen and (max-width:780px) {
  .dea-content {
    /* max-width: 450px; */
  }

  .dea-content h1,
  .dea-h1 {
    font-size: 30px;
    line-height: 1;
  }

  .dea-content h3 {
    font-size: 15px;
  }

  .dea-content p {
    font-size: 12px;
    line-height: 1.2;
  }
}

@media only screen and (max-width:385px) {
  .dea-content {
    /* max-width: 350px; */
  }

  .dea-content h1,
  .dea-h1 {
    font-size: 20px;
    line-height: 1;
  }

  .dea-content h3 {
    font-size: 12px;
  }

  .dea-content p {
    font-size: 10px;
    line-height: 1;
  }
}