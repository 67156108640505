/* `````````````````````````` */
/* Featured In */
/* ````````````````````````` */
.section-featured {
  padding: 3.8rem 0 3.8rem 0;
}

.feat-container {
  max-width: 100rem;
  margin: 0 auto;
  padding: 0 2.2rem;
}

.feat-heading-featured-in {
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2rem;
  color: #888888;
}

.feat-logos {
  display: flex;
  justify-content: space-around;
}

.feat-logos img {
  height: 2.2rem;
  filter: brightness(0);
  opacity: 50%;
}

@media only screen and (max-width: 1030px) {
  .feat-heading-featured-in {
    font-size: 0.75rem;
    letter-spacing: 0.5px;
  }

  .feat-logos img {
    height: 1.75rem;
  }
}

@media only screen and (max-width:780px) {
  .section-featured {
    padding: 2.4rem 0 2.4rem 0;
  }

  .feat-heading-featured-in {
    font-size: 0.65rem;
    letter-spacing: 0.5px;
    margin-bottom: 1rem;
  }

  .feat-logos img {
    height: 1.25rem;
  }
}

@media only screen and (max-width:385px) {
  .section-featured {
    padding: 1.4rem 0 1.4rem 0;
  }

  .feat-logos {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .feat-heading-featured-in {
    font-size: 0.55rem;
    letter-spacing: 0.5px;
    margin-bottom: 1rem;
  }

  .feat-logos img {
    height: 1rem;
  }
}