.dashboard {
  /* position: relative; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding: 6rem 4% 6rem; */
  overflow: hidden;
  /* background-image: linear-gradient(to top right, #091550, #0e5c01); */
  box-shadow: -15px 15px 15px rgb(0, 0, 0, 0.2);
}

/* .dash-wrap {
  max-width: 100%;
  max-height: 100%;
  padding: 2rem;
  position: relative;
  background: linear-gradient(to top right, #2f4b9c, #438604);
} */

.nav-content {
  flex: 0 0 4%;
  max-width: 100%;
  height: 100%;
  padding: 1.4rem 0.7rem;
  background-color: #21377f;
  color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  box-shadow: 20px 0px 25px 14px rgba(0, 0, 0, 0.2);
}

.expand {
  flex: 0 0 10%;
}

.nav-content h1 {
  font-size: 50px;
  font-weight: 300;
  line-height: 1.2;
  color: #ffffff;
  padding: 0.5rem;
  /* opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s; */
}

.nav-content h1::after {
  content: "";
  display: block;
  width: 70%;
  padding-top: 3px;
  border-bottom: 4px solid #ffffff;
}

.nav-content p {
  font-size: 16px;
  /* margin: 15px 0 10px; */
  padding: 1rem;
  line-height: 1.6;
  color: #ffffff;
  /* opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s; */
}

.nav-ele {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav-ele p {
  padding: 0 1rem;
}

.righter {
  transform: rotate(-90deg);
}

.lefter {
  transform: rotate(90deg);
}

.nav-btn {
  font-size: 16px;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  background-color: #fafafa;
  color: #091550;
  /* border: 3px solid #667B9F; */
  padding: 1rem 3rem;
  border-radius: 9px;
  box-shadow: -15px 15px 15px rgb(0, 0, 0, 0.2);
}

.nav-opt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 0.8rem;
}

.aux-opt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 0.8rem;
}

.dash-logo {
  height: 2rem;
  width: 2rem;
  align-self: center;
  border-radius: 50%;
  /* padding: 0.5rem; */
}

.dash-content {
  /* max-width: 630px; */
  width: 100%;
  height: 100%;
  background-color: #f7f5f7;
}

.dash-card {
  /* Add shadows to create the "card" effect */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-around;
  padding: 2rem;
  height: 100%;
  gap: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 4px;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); */
}

/* On mouse-over, add a deeper shadow */
.dash-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.dash-container {
  padding: 2px 16px;
}

.dash-content h1 {
  font-size: 50px;
  font-weight: 300;
  line-height: 1.2;
  color: #201f20;
  padding: 1rem;
  /* opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s; */
}

.dash-content h3 {
  font-size: 30px;
  /* font-weight: 300; */
  line-height: 1.2;
  color: #201f20;
  padding: 1rem;
  /* opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s; */
}

.dash-content p {
  font-size: 16px;
  margin: 15px 0 10px;
  line-height: 1.6;
  color: #201f20;
  /* opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s; */
}

.dash-points {
  margin-top: 2rem;
}

.dash-form {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3.2rem;
  row-gap: 2.4rem; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 1rem;
}

.dash-form input,
.dash-form select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-family: inherit;
  color: inherit;
  border: none;
  background-color: #eaeaea;
  border-radius: 30px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.pw-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dash-btn {
  width: 50%;
  align-self: center;
  font-size: 16px;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
  background-color: #1f3364;
  color: #ffffff;
  border: 1px solid #1f3364;
  padding: 0.75rem 1rem;
  margin: 0.5rem;
  border-radius: 28px;
}

.dash-social-links {
  padding: 24px 0;
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 1.4rem;
}

.dash-footer-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dash-social-icon {
  height: 2.1rem;
  width: 2.1rem;
  filter: invert(1);
}

.nav-icon {
  height: 1.8rem;
  width: 1.8rem;
  filter: invert(1);
}

.header {
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.header form {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header input[type="text"] {
  /* float: right; */
  width: 100%;
  margin: 0.5rem 0 0.5rem 0.5rem;
  padding: 0.5rem;
  border: none;
  /* margin-top: 8px; */
  /* margin-right: 16px; */
  font-size: 16px;
}

.search-icons {
  border: none;
  background: #ffffff;
  margin: 0.5rem 0.5rem 0.5rem 0;
  padding: 0.32rem;
  height: auto;
  width: auto;
  border: none;
}

.main-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.card-img {
  height: 2.8rem;
  width: 2.8rem;
  background-color: #1097e2;
  border-radius: 50%;
  padding: 0.5rem;
  filter: invert(1);
}

.pie-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 0.8rem;
}

.dash-pie-card {
  /* Add shadows to create the "card" effect */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 2rem;
  height: 100%;
  flex: 0 0 30%;
  gap: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 4px;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); */
}

/* On mouse-over, add a deeper shadow */
.dash-pie-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.pie {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: conic-gradient(
    deepskyblue 0% 25%,
    orangered 25% 85%,
    #ffffff 85%
  );
}

.pie.hollow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pie.hollow::after {
  content: "";
  position: absolute;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: #ffffff;
}

.pie.hollow span {
  font-size: 24pt;
  z-index: 2;
  font-weight: bold;
  font-family: "JetBrains Mono", sans-serif;
}
