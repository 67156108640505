@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@700&display=swap');

.prod-footer {
  width: 100%;
  height: 6rem;
  /* background-color: #002365; */
  /* border-top: 0.1rem solid #f49038;
  border-bottom: 0.1rem solid #f49038; */
}

.prod-footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 1.4;
  /* color: #fafafa; */
  color: #222222;
  text-decoration: none;
  /* font-weight: 500; */
  padding: 2rem 2rem 0;
  /* padding-bottom: 12px; */
  transition: 0.3s;
  opacity: 0;
  animation: slideTop 0.75s ease forwards;
}

.prod-footer-container a {
  text-decoration: none;
  /* color: #fafafa; */
  color: #222222;
}

.prod-footer-container a:hover {
  color: #f49038;
  animation: shakesides 0.5s;
}

.prod-logo-col {
  /* margin-bottom: 24px; */
  padding-top: 8px;
}

.prod-footer-logo {
  font-family: 'Kalam', cursive;
  font-size: 18px;
}

.prod-footer-heading {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 16px;
}

.prod-footer-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.prod-footer-icons {
  height: 16px;
  margin-right: 10px;
  filter: invert(1);
}

.prod-address {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prod-license-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 36%;
}

.prod-copyright {
  padding-top: 0.2rem;
  text-align: center;
  font-size: 14px;
  /* color: #fafafa; */
  color: #222222;
}

.prod-social-links {
  padding-left: 12rem;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 2rem;
}

.prod-social-icon {
  height: 1.8rem;
  width: 1.8rem;
  /* filter: invert(1); */
}

.prod-social-icon:hover {
  filter: invert(68%) sepia(17%) saturate(4705%) hue-rotate(336deg) brightness(103%) contrast(91%);
}

@keyframes slideTop {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes shakesides {
  0% {
    transform: translateX(0)
  }

  25% {
    transform: translateX(5px)
  }

  50% {
    transform: translateX(-5px)
  }

  75% {
    transform: translateX(5px)
  }

  100% {
    transform: translateX(0)
  }
}

@media only screen and (max-width:780px) {

  .prod-footer-container {
    font-size: 12px;
  }

  .prod-footer-logo {
    font-size: 16px;
  }

  .prod-social-links {
    padding-left: 0;
  }

  .prod-license-col {
    width: 46%;
  }

  .prod-copyright {
    font-size: 12px;
  }
}

@media only screen and (max-width:385px) {
  .prod-footer {

    height: 10rem;
  }

  .prod-footer-container {
    flex-direction: column;
    gap: 1rem;
    font-size: 10px;
    padding: 1rem 1rem 0;
    line-height: 1;
  }

  .prod-footer-logo {
    font-size: 12px;
  }

  .prod-social-icon {
    height: 1rem;
    width: 1rem;
  }

  .prod-license-col {
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
  }

  .prod-copyright {
    font-size: 10px;
  }
}