@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@700&display=swap');

.footer-DataEnggAna {
  width: 100%;
  height: 24rem;
  background-color: #002365;
}

.container-DataEnggAna {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  font-size: 14px;
  line-height: 1.6;
  color: #fafafa;
  text-decoration: none;
  font-weight: 500;
  padding-top: 2rem;
  padding-bottom: 12px;
  transition: 0.3s;
  opacity: 0;
  animation: slideTop 0.75s ease forwards;
}

.container-DataEnggAna a {
  text-decoration: none;
  color: #fafafa;
}

.container-DataEnggAna a:hover {
  color: #f49038;
  animation: shakesides 0.5s;
}

.logo-col-DataEnggAna {
  /* margin-bottom: 24px; */
}

.footer-logo-DataEnggAna {
  font-family: 'Kalam', cursive;
  font-size: 24px;
}

.footer-heading-DataEnggAna {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 16px;
}

.footer-link-DataEnggAna {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer-icons-DataEnggAna {
  height: 16px;
  margin-right: 10px;
  filter: invert(1);
}

.address-DataEnggAna {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyright-DataEnggAna {
  position: relative;
  top: 36%;
  padding-top: 1.2rem;
  text-align: center;
  font-size: 12px;
  color: #fafafa;
}

.social-links-DataEnggAna {
  padding-top: 24px;
  list-style: none;
  display: flex;
  justify-content: space-around;
  gap: 2.4rem;
}

.social-icon-DataEnggAna {
  height: 2.1rem;
  width: 2.1rem;
  filter: invert(1);
}

@keyframes slideTop {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes shakesides {
  0% {
    transform: translateX(0)
  }

  25% {
    transform: translateX(5px)
  }

  50% {
    transform: translateX(-5px)
  }

  75% {
    transform: translateX(5px)
  }

  100% {
    transform: translateX(0)
  }
}

@media only screen and (max-width: 1030px) {
  .footer-DataEnggAna {
    height: 18rem;
  }

  .container-DataEnggAna {
    font-size: 12px;
    line-height: 1.3;
  }

  .social-links-DataEnggAna {
    gap: 1.4rem;
  }

  .footer-heading-DataEnggAna {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 14px;
  }

  .copyright-DataEnggAna {
    top: 28%;
    font-size: 10px;
  }
}

@media only screen and (max-width: 780px) {
  .footer-DataEnggAna {
    height: 14rem;
  }

  .container-DataEnggAna {
    font-size: 10px;
    line-height: 1;
    padding-top: 1rem;
    padding-bottom: 10px;
  }

  .footer-logo-DataEnggAna {
    font-family: 'Kalam', cursive;
    font-size: 18px;
  }

  .social-links-DataEnggAna {
    gap: 0.5rem;
    padding-top: 18px;
  }

  .social-icon-DataEnggAna {
    height: 1.1rem;
    width: 1.1rem;
  }

  .footer-heading-DataEnggAna {
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 12px;
  }

  .copyright-DataEnggAna {
    top: 24%;
    font-size: 8px;
  }
}

@media only screen and (max-width:385px) {
  .footer-DataEnggAna {
    height: 16rem;
  }

  .container-DataEnggAna {
    font-size: 8px;
    line-height: 1;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 1rem 8px 8px;
  }

  .footer-logo-DataEnggAna {
    font-family: 'Kalam', cursive;
    font-size: 14px;
  }

  .social-links-DataEnggAna {
    gap: 0.5rem;
    padding-top: 14px;
  }

  .footer-heading-DataEnggAna {
    font-size: 10px;
    font-weight: 500;
    padding-bottom: 10px;
  }

  .license-col-DataEnggAna {
    align-self: flex-start;
  }

  .copyright-DataEnggAna {
    top: 0%;
    font-size: 6px;
  }
}