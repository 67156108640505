.home {
  position: relative;
  width: 100%;
  height: auto;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  /* padding: 0 3% 8%; */
  overflow: hidden;
  scroll-behavior: smooth;
}

.landingContent {
  padding: 10rem 3% 4rem;
  background-image: url("../../assets/background-homepage-design.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.landing {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 16vh 0;
}

.combine-bg--container {
  height: 1500px; /* 150% of the viewport height */
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content vertically */
  background: url("../../assets/background-homepage-cropped.png") no-repeat
    center center;
  background-size: 100% 500px; /* Adjusts the size to fit within the container */
  background-color: white; /* Ensures white space around the image */
}

/* .service {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: 6rem 4% 0;
} */

.home-content {
  max-width: 730px;
  height: 100vh;
}

.home-content h1 {
  font-size: 50px;
  line-height: 1.2;
  color: #ffffff;
  /* color: #222222; */
  /* text-transform: uppercase; */
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.home-content h3 {
  font-size: 30px;
  color: #ffffff;
  /* color: #222222; */
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
}

.home-content p {
  font-size: 16px;
  color: #ffffff;
  margin: 15px 0 30px;
  line-height: 1.6;
  letter-spacing: 0.6px;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}

.home-btn-line {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}

.home-btn {
  display: inline-block;
  float: left;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 16px;
  border-radius: 9px;
  cursor: pointer;
  font-family: inherit;
  background-color: #1890ff;
  color: #fafafa;
  border: 3px solid #1890ff;
  margin: 15px 0 15px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}

.h-btn {
  font-size: 18px !important;
}

.home-points {
  margin-top: 2rem;
}

/* .home-points h3 {
  font-size: 40px;
  color: #667ba3;
} */

.home-points p {
  font-size: 16px;
  margin: 15px 0 30px;

  display: flex;
  align-items: center;
}

.home-img1 {
  position: relative;
  /* right: -7%; */
  width: 450px;
  height: 450px;
  /* transform: rotate(45deg); */
}

.home-img1 .rhombus1 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #eaeaea;
  border: 25px solid #667ba3;
  box-shadow: -15px 15px 15px rgb(0, 0, 0, 0.2);
  opacity: 0;
  animation: zoomOut 1s ease forwards;
  animation-delay: 1.75s;
}

.home-img1 .rhombus1 img {
  position: absolute;
  /* top: 110px; */
  /* left: -250px; */
  /* max-width: 750px; */
  transform: rotate(-90deg);
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0;
  animation: boxImage 1s ease forwards;
  animation-delay: 2s;
}

.home-img3 {
  position: relative;
  /* right: -7%; */
  width: 450px;
  height: 450px;
  transform: rotate(45deg);
}

.home-img3 .rhombus3 {
  position: absolute;
  right: 108%;
  bottom: 108%;
  width: 100%;
  height: 100%;
  background-color: #eaeaea;
  /* border: 25px solid #667ba3; */
  border: 25px solid #f49636;
  box-shadow: -15px 15px 15px rgb(0, 0, 0, 0.2);
  opacity: 0;
  animation: zoomOut 1s ease forwards;
  animation-delay: 1.75s;
}

.home-img3 .rhombus3 img {
  position: absolute;
  /* top: 110px; */
  /* left: -250px; */
  /* max-width: 750px; */
  transform: rotate(-90deg);
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0;
  animation: boxImage 1s ease forwards;
  animation-delay: 2s;
}

.home-img3 .rhombus4 {
  position: absolute;
  left: 108%;
  top: 108%;
  width: 100%;
  height: 100%;
  background-color: #eaeaea;
  /* border: 25px solid #667ba3; */
  border: 25px solid #4b9c02;
  box-shadow: -15px 15px 15px rgb(0, 0, 0, 0.2);
  opacity: 0;
  animation: zoomOut 1s ease forwards;
  animation-delay: 1.75s;
}

.home-img3 .rhombus4 img {
  position: absolute;
  /* top: 110px; */
  /* left: -250px; */
  /* max-width: 750px; */
  transform: rotate(-90deg);
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0;
  animation: boxImage 1s ease forwards;
  animation-delay: 2s;
}

.home-img3 .rhombus5 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #eaeaea;
  /* border: 25px solid #667ba3; */
  border: 25px solid #ffffff;
  box-shadow: -15px 15px 15px rgb(0, 0, 0, 0.2);
  opacity: 0;
  animation: zoomOut 1s ease forwards;
  animation-delay: 1.75s;
}

.home-img3 .rhombus5 img {
  position: absolute;
  /* top: 110px; */
  /* left: -250px; */
  /* max-width: 750px; */
  transform: rotate(-90deg);
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0;
  animation: boxImage 1s ease forwards;
  animation-delay: 2s;
}

.home .rhombus2 {
  position: absolute;
  top: -25%;
  right: -25%;
  width: 700px;
  height: 700px;
  background: #667ba3;
  transform: rotate(45deg);
  z-index: -1;
  /* check why this is making box dissappear */
  opacity: 0;
  animation: rhombus2 1s ease forwards;
  animation-delay: 1.75s;
}

.pointer-icon {
  height: 18px;
  margin-right: 10px;
  transform: rotate(45deg);
}

.productFullImage {
  width: 100%;
  height: 2800px;
  margin-bottom: 4rem;
  background-image: url("../../assets/gleafink-product-infographic.png");
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: 1260px 2800px;
}

@keyframes slideBottom {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes rhombus2 {
  0% {
    right: -40px;
    opacity: 0;
  }

  100% {
    right: -25px;
    opacity: 1;
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes boxImage {
  0% {
    transform: translateX(300px, -300px) scale(0) rotate(-45deg);
    opacity: 0;
  }

  100% {
    transform: translateX(0, 0) scale(1) rotate(-45deg);
    opacity: 1;
  }
}

@media only screen and (max-width: 1030px) {
  .home-content {
    max-width: 700px;
  }

  .home-content h1 {
    font-size: 40px;
  }

  .home-content h3 {
    font-size: 20px;
  }

  .home-content p {
    font-size: 14px;
    line-height: 1.4;
  }

  .pointer-icon {
    height: 14px;
  }

  .home-img1 {
    width: 350px;
    height: 350px;
  }

  .home-img3 {
    width: 300px;
    height: 300px;
  }
}

@media only screen and (max-width: 780px) {
  .home {
    padding: 8rem 2% 0;
  }

  .home-content {
    max-width: 450px;
  }

  .home-content h1 {
    font-size: 30px;
  }

  .home-content h3 {
    font-size: 15px;
  }

  .home-content p {
    font-size: 12px;
    line-height: 1.2;
  }

  .pointer-icon {
    height: 12px;
  }

  .home-img1 {
    width: 300px;
    height: 300px;
  }

  .home-img1 .rhombus1 {
    border: 20px solid #667ba3;
  }

  .service {
    padding: 4rem 2% 0;
  }

  .home-img3 {
    width: 250px;
    height: 250px;
  }

  .home-img3 .rhombus3 {
    border: 20px solid #f49636;
  }

  .home-img3 .rhombus5 {
    border: 20px solid #ffffff;
  }

  .home-img3 .rhombus4 {
    border: 20px solid #4b9c02;
  }
}

@media only screen and (max-width: 385px) {
  .home {
    padding: 6rem 2% 1rem;
    flex-direction: column;
  }

  .home-content {
    max-width: 100%;
  }

  .home-content h1 {
    font-size: 20px;
  }

  .home-content h3 {
    font-size: 12px;
  }

  .home-content p {
    font-size: 10px;
    line-height: 1;
  }

  .pointer-icon {
    height: 10px;
  }

  .home-img1 {
    width: 250px;
    height: 250px;
  }

  .home-img1 .rhombus1 {
    border: 15px solid #667ba3;
  }

  .service {
    padding: 3rem 2% 0;
  }

  .home-img3 {
    display: none;
  }
}
