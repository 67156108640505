.login {
  /* position: relative; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 6rem 4% 6rem;
  overflow: hidden;
  background-image: linear-gradient(to top right, #091550, #0e5c01);
  box-shadow: -15px 15px 15px rgb(0, 0, 0, 0.2);
}

.login-wrap {
  width: 100%;
  height: 100%;
  padding: 2rem;
  position: relative;
  background: linear-gradient(to top right, #2f4b9c, #438604);
}

.welcome-content {
  flex: 0 0 50%;
  max-width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: baseline;
  gap: 1rem;
}

.welcome-content h1 {
  font-size: 50px;
  font-weight: 300;
  line-height: 1.2;
  color: #ffffff;
  padding: 0.5rem;
  /* opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s; */
}

.welcome-content h1::after {
  content: "";
  display: block;
  width: 70%;
  padding-top: 3px;
  border-bottom: 4px solid #ffffff;
}

.welcome-content p {
  font-size: 16px;
  /* margin: 15px 0 10px; */
  padding: 1rem;
  line-height: 1.6;
  color: #ffffff;
  /* opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s; */
}

.welcome-btn {
  font-size: 16px;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  background-color: #fafafa;
  color: #091550;
  /* border: 3px solid #667B9F; */
  padding: 1rem 3rem;
  border-radius: 9px;
  box-shadow: -15px 15px 15px rgb(0, 0, 0, 0.2);
}

.login-logo {
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  padding: 0.5rem;
}

.login-content {
  /* max-width: 630px; */
}

.login-card {
  /* Add shadows to create the "card" effect */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 9px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));

}

/* On mouse-over, add a deeper shadow */
.login-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.login-container {
  padding: 2px 16px;
}

.login-content h1 {
  font-size: 50px;
  font-weight: 300;
  line-height: 1.2;
  color: #ffffff;
  padding: 1rem;
  /* opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s; */
}

.login-content h3 {
  font-size: 30px;
  font-weight: 300;
  line-height: 1.2;
  color: #ffffff;
  padding: 1rem;
  /* opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s; */
}

.login-content p {
  text-align: center;
  font-size: 16px;
  margin: 15px 0 10px;
  line-height: 1.6;
  color: #ffffff;
  /* opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s; */
}

.login-points {
  margin-top: 2rem;
}

.login-form {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3.2rem;
  row-gap: 2.4rem; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 1rem;
}

.login-form input,
.login-form select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-family: inherit;
  color: inherit;
  border: none;
  background-color: #eaeaea;
  border-radius: 30px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.pw-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-btn {
  width: 50%;
  align-self: center;
  font-size: 16px;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
  background-color: #1f3364;
  color: #ffffff;
  border: 1px solid #1f3364;
  padding: 0.75rem 1rem;
  margin: 0.5rem;
  border-radius: 28px;
}

.login-social-links {
  padding: 24px 0;
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 1.4rem;
}

.login-footer-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.login-social-icon {
  height: 2.1rem;
  width: 2.1rem;
  filter: invert(1);
}