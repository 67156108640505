.connect {
  position: relative;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 6rem 4%;
  /* overflow: hidden; */
  /* margin: 8rem auto 4rem; */
}

.connect-content {
  height: 100%;
  overflow: auto;
  /* max-width: 630px; */
  margin-top: 4rem;
}

.connect-content h1 {
  font-size: 50px;
  line-height: 1.2;
  /* color: #002365; */
  color: #222222;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.connect-content h3 {
  font-size: 30px;
  /* color: #667ba3; */
  color: #222222;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
}

.connect-content p {
  font-size: 16px;
  margin: 15px 0 10px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}

.connect-points {
  margin-top: 2rem;
}

.connect-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 20px;
  padding: 4px;
}

/* .connect-form input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-family: inherit;
  color: inherit;
  border: none;
  background-color: #eaeaea;
  border-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
} */

.connect-btn {
  font-size: 16px;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  background-color: #fafafa;
  color: #667b9f;
  border: 3px solid #667b9f;
  padding: 1.2rem;
  border-radius: 9px;
}
