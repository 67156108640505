.prod {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr; */
  gap: 2vw;
  /* padding: 3rem 4% 8%; */
  overflow: hidden;
  /* background-image: url("../../assets/gleafinkBackground2.jpeg"); */
  /* background-color: #cccccc; */
  /* background-position: center; */
  /* Center the image */
  /* background-repeat: no-repeat; */
  /* Do not repeat the image */
  /* background-size: cover; */
  /* box-shadow: 0 0 4px 4px white inset; */
  /* border-radius: 8px; */
  /* border-top: 0.5rem solid #f49038;
  border-bottom: 0.5rem solid #f49038; */
}

#Service2 {
  padding: 6rem 1rem 2rem;
}

.prodBackground {
  margin-top: 150px;
  height: 800px;
  padding-inline: 3%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.prod h1 {
  padding-top: 3rem;
  font-size: 50px;
  line-height: 1.2;
  /* color: #002365; */
  color: #222222;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-content {
  /* max-width: 630px; */
  height: 24rem;
  width: 100%;
  text-align: center;
  /* padding: 0 4% 0; */
}

.prod-content h1,
.prod-h1 {
  font-size: 50px;
  line-height: 1.2;
  text-align: center;
  /* color: #002365; */
  color: #222222;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-card {
  /* Add shadows to create the "card" effect */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 9px;
  background: #ffffff;
}

/* On mouse-over, add a deeper shadow */
.prod-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.0125);
}

.prod-content h3 {
  font-size: 30px;
  /* color: #667ba3; */
  color: #222222;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
}

/* Add some padding inside the card prod-container */
.prod-container {
  padding: 2px 16px;
}

.prod-cardII {
  /* Add shadows to create the "card" effect */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 9px;
  background: #ffffff;
  padding: 28px 16px;
}

/* On mouse-over, add a deeper shadow */
.prod-cardII:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.0125);
}

/* Add rounded corners to the top left and the top right corner of the image */
img {
  border-radius: 5px 5px 0 0;
}

.prod-btn {
  font-size: 16px;
  font-weight: 700;
  padding: 8px 16px;
  border-radius: 9px;
  cursor: pointer;
  font-family: inherit;
  background-color: #fafafa;
  color: #667b9f;
  border: 3px solid #667b9f;
}

/* .prod-content h2 {
  font-size: 40px;
  color: #9e447c;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
} */

/* .prod-content h3 {
  font-size: 30px;
  color: #667ba3;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
} */

.prod-content p {
  font-size: 16px;
  margin: 15px 0 30px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}

@keyframes slideBottom {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes rhombus2 {
  0% {
    right: -40px;
    opacity: 0;
  }

  100% {
    right: -25px;
    opacity: 1;
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes boxImage {
  0% {
    transform: translateX(300px, -300px) scale(0) rotate(-45deg);
    opacity: 0;
  }

  100% {
    transform: translateX(0, 0) scale(1) rotate(-45deg);
    opacity: 1;
  }
}

@media only screen and (max-width: 1030px) {
  .prod h1,
  .prod-h1 {
    font-size: 40px;
    line-height: 1;
  }

  .prod-content {
    height: 20rem;
  }

  .prod-content h3 {
    font-size: 20px;
  }

  .prod-content p {
    font-size: 14px;
    line-height: 1.4;
  }
}

@media only screen and (max-width: 780px) {
  .prod h1,
  .prod-h1 {
    font-size: 30px;
    line-height: 1;
  }

  .prod-content {
    padding: 4rem 2% 4rem;
    height: 15rem;
  }

  .prod-content h3 {
    font-size: 15px;
  }

  .prod-content p {
    font-size: 12px;
    line-height: 1.2;
  }
}

/* Product cards design css written on 27/12/2024 */
/* .prodCard-card-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.prodCard-card-expanded {
  position: absolute;
  z-index: 10;
  width: 1369px;
  height: 384px;
}

.prodCard-card-expanded .prodCard-card-content {
  max-height: 500px; 
} */

.prodCard-ind-overview {
  /* max-width: 630px; */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 8%;
  /* background-color: #1853A0; */
}

.prodCard-ind-overview p {
  /* color: #ffffff; */
  font-size: 16px;
  margin: 15px 0 30px;
  line-height: 1.6;
  /* opacity: 0; */
  /* animation: slideLeft 0.1s ease forwards; */
  /* animation-delay: 1.5s; */
}

.prodCard-ind-big-card {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  height: 34rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 9px;
}

.prodCard-ind-big-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 40%;
  gap: 10%;
  padding: 1.4rem 0;
  cursor: pointer;
  /* background-color: #071c20; */
  background-color: #E6F4FF;
}

.prodCard-border-purple {
  width: 100%;
  padding: 7px 0;
  /* border-right: 4px solid #1890ff;
  background-color: #10363e; */
  border-right: 4px solid #489c04;
  background-color: #2166e5;
}

@media only screen and (max-width: 385px) {
  .prod {
    flex-direction: column;
  }

  .prod h1,
  .prod-h1 {
    font-size: 20px;
    line-height: 1;
  }

  .prod-content {
    padding: 2rem 2% 2rem;
    height: 10rem;
  }

  .prod-content h3 {
    font-size: 12px;
  }

  .prod-content p {
    font-size: 10px;
    line-height: 1;
  }
}
