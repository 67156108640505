.core {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6rem 4% 4%;
  overflow: hidden;
  /* background-image: url("../../assets/gleafinkBackground1.jpg");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  /* Resize the background image to cover the entire container */
  /* border-top: 0.5rem solid #f49038;
  border-bottom: 0.5rem solid #f49038; */
}

.core-content {
  /* max-width: 630px; */
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

.core-content h1 {
  font-size: 50px;
  line-height: 1.2;
  /* color: #002365; */
  color: #222222;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.core-content h3 {
  font-size: 30px;
  /* color: #667ba3; */
  color: #222222;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
}

.core-content p {
  font-size: 16px;
  margin: 15px 0 30px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}

.core-points {
  margin-top: 2rem;
}


/* .core-points h3 {
  font-size: 40px;
  color: #667ba3;
} */

.core-points p {
  font-size: 16px;
  margin: 15px 0 30px;
}

.core-img {
  position: relative;
  right: -7%;
  width: 450px;
  height: 450px;
  transform: rotate(45deg);
}

.core-img .rhombus {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #eaeaea;
  border: 25px solid #667ba3;
  box-shadow: -15px 15px 15px rgb(0, 0, 0, 0.2);
  opacity: 0;
  animation: zoomOut 1s ease forwards;
  animation-delay: 1.75s;
}

.core-img .rhombus img {
  position: absolute;
  top: 110px;
  left: -250px;
  max-width: 750px;
  transform: rotate(-45deg);
  /* opacity: 0;
  animation: boxImage 1s ease forwards;
  animation-delay: 2s; */
}

.core .rhombus2 {
  position: absolute;
  top: -25%;
  right: -25%;
  width: 700px;
  height: 700px;
  background: #667ba3;
  transform: rotate(45deg);
  z-index: -1;
  /* check why this is making box dissappear */
  opacity: 0;
  animation: rhombus2 1s ease forwards;
  animation-delay: 1.75s;
}

@keyframes slideBottom {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes rhombus2 {
  0% {
    right: -40px;
    opacity: 0;
  }

  100% {
    right: -25px;
    opacity: 1;
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes boxImage {
  0% {
    transform: translateX(300px, -300px) scale(0) rotate(-45deg);
    opacity: 0;
  }

  100% {
    transform: translateX(0, 0) scale(1) rotate(-45deg);
    opacity: 1;
  }
}

@media only screen and (max-width: 1030px) {
  .core-content h1 {
    font-size: 40px;
    line-height: 1;
  }

  .core-content h3 {
    font-size: 20px;
  }

  .core-points p {
    font-size: 14px;
    line-height: 1.4;
  }
}

@media only screen and (max-width:780px) {
  .core {
    padding: 3rem 2% 0;
  }

  .core-content h1 {
    font-size: 30px;
    line-height: 1;
  }

  .core-content h3 {
    font-size: 15px;
  }

  .core-points p {
    font-size: 12px;
    line-height: 1.2;
  }
}

@media only screen and (max-width:385px) {
  .core {
    padding: 2rem 2% 0;
  }

  .core-content h1 {
    font-size: 20px;
    line-height: 1;
  }

  .core-content h3 {
    font-size: 12px;
  }

  .core-points p {
    font-size: 10px;
    line-height: 1;
  }
}