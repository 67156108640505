/* .prod {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6rem 4% 0;
  overflow: hidden;
  border-top: 0.5rem solid #f49038;
  border-bottom: 0.5rem solid #f49038;
} */

.prod-ind-content {
  /* max-width: 630px; */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 10% 4% 0;
  gap: 0.5rem;
  /* background-color: #1853A0; */
}

.prod-ind-content h1 {
  font-size: 50px;
  font-weight: 200;
  line-height: 1.2;
  color: #222222;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-content h2 {
  font-size: 40px;
  color: #1890ff;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-content h3 {
  font-size: 30px;
  color: #ffffff;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
}

.prod-ind-content p {
  color: #484848;
  font-size: 16px;
  margin: 15px 0 30px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}

.prod-points {
  margin-top: 2rem;
}

.prod-ind-sub {
  font-size: 18px;
  font-weight: 700;
  color: #1890ff;
  margin: 15px 0 30px;
}


/* .prod-points h3 {
  font-size: 40px;
  color: #667ba3;
} */

.prod-points p {
  font-size: 16px;
  margin: 15px 0 30px;
}

.prod-btn-line {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}

.prod-btn {
  display: inline-block;
  float: left;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 16px;
  border-radius: 9px;
  cursor: pointer;
  font-family: inherit;
  background-color: #1890ff;
  color: #fafafa;
  border: 3px solid #1890ff;
  margin: 15px 0 15px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}

.p-btn {
  font-size: 18px !important;
}

.prod-ind-features {
  /* max-width: 630px; */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 0 4% 0;
  /* background-color: #1853A0; */
}

.prod-ind-features h1 {
  font-size: 50px;
  font-weight: 200;
  line-height: 1.2;
  color: #222222;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-features h2 {
  font-size: 40px;
  font-weight: 200;
  color: #1890ff;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-features h3 {
  font-size: 30px;
  font-weight: 200;
  color: #484848;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
}

.prod-ind-features p {
  color: #484848;
  font-size: 16px;
  margin: 15px 0 30px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}

.prod-ind-features3 {
  /* max-width: 630px; */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 4%;
  /* background-color: #1853A0; */
}

.prod-ind-features3 h1 {
  font-size: 50px;
  font-weight: 200;
  line-height: 1.2;
  color: #222222;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-features3 h2 {
  font-size: 40px;
  font-weight: 200;
  color: #1890ff;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-features3 h3 {
  font-size: 30px;
  font-weight: 200;
  color: #484848;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
}

.prod-ind-features3 p {
  color: #484848;
  font-size: 16px;
  margin: 15px 0 30px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}


.prod-card-container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 7rem;
  row-gap: 7rem;
  margin: 3rem;
}

.prod-ind-card {
  /* Add shadows to create the "card" effect */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 9px;
}

/* On mouse-over, add a deeper shadow */
.prod-ind-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.prod-ind-container {
  padding: 2px 16px;
}

.prod-card-container2 {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 7rem;
  row-gap: 7rem;
  margin: 3rem;
}

.prod-ind-card2 {
  /* Add shadows to create the "card" effect */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 9px;
}

/* On mouse-over, add a deeper shadow */
.prod-ind-card2:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.prod-ind-container2 {
  padding: 2px 16px;
}

.prod-card-container3 {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 7rem;
  row-gap: 7rem;
  margin: 3rem;
}

.prod-ind-card3 {
  /* Add shadows to create the "card" effect */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 9px;
}

/* On mouse-over, add a deeper shadow */
.prod-ind-card3:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.prod-ind-container3 {
  padding: 2px 16px;
}

.prod-ind-news {
  /* max-width: 630px; */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 8% 4% 0;
  gap: 0.5rem;
  /* background-color: #1853A0; */
}

.prod-ind-news h1 {
  font-size: 50px;
  font-weight: 200;
  line-height: 1.2;
  color: #222222;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-news h2 {
  font-size: 40px;
  font-weight: 200;
  color: #1890ff;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-news h3 {
  font-size: 30px;
  font-weight: 200;
  color: #484848;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
}

.prod-ind-news p {
  color: #484848;
  font-size: 16px;
  margin: 15px 0 30px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}

.prod-ind-proph {
  /* max-width: 630px; */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 8% 4% 0;
  gap: 0.5rem;
  /* background-color: #1853A0; */
}

.prod-ind-proph h1 {
  font-size: 50px;
  font-weight: 200;
  line-height: 1.2;
  color: #222222;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-proph h2 {
  font-size: 40px;
  font-weight: 200;
  color: #1890ff;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-proph h3 {
  font-size: 30px;
  font-weight: 200;
  color: #484848;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
}

.prod-ind-proph p {
  color: #484848;
  font-size: 16px;
  margin: 15px 0 30px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}

.prod-ind-cloud {
  /* max-width: 630px; */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 8% 4% 0;
  gap: 0.5rem;
  /* background-color: #1853A0; */
  margin-bottom: 20px;
}

.prod-ind-cloud h1 {
  font-size: 50px;
  font-weight: 200;
  line-height: 1.2;
  color: #222222;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-cloud h2 {
  font-size: 40px;
  font-weight: 200;
  color: #1890ff;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-cloud h3 {
  font-size: 30px;
  font-weight: 200;
  color: #484848;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
}

.prod-ind-cloud p {
  color: #484848;
  font-size: 16px;
  margin: 15px 0 30px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}

.prod-ind-desc {
  /* max-width: 630px; */
  /* position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center; */
  width: 100%;
  text-align: center;
  padding: 8% 4% 0;
  gap: 0.5rem;
  /* background-color: #1853A0; */
}

.prod-ind-desc h1 {
  font-size: 50px;
  font-weight: 200;
  line-height: 1.2;
  color: #222222;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-desc h2 {
  font-size: 40px;
  font-weight: 200;
  color: #1890ff;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-desc h3 {
  font-size: 30px;
  font-weight: 200;
  color: #484848;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
}

.prod-ind-desc p {
  color: #484848;
  font-size: 16px;
  margin: 15px 0 30px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1.5s;
}

.prod-ind-csub {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  border-bottom: 1px solid #484848;
  cursor: pointer;
}

.prod-ind-csub p {
  margin: 0 !important;
}

.highli {
  font-weight: 700;
  border-bottom: 2px solid #484848;
}

.prod-ind-overview {
  /* max-width: 630px; */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 8%;
  /* background-color: #1853A0; */
}

.prod-ind-overview h1 {
  font-size: 50px;
  font-weight: 200;
  line-height: 1.2;
  color: #222222;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-overview h2 {
  font-size: 40px;
  font-weight: 200;
  color: #1890ff;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 1s;
}

.prod-ind-overview h3 {
  font-size: 30px;
  font-weight: 200;
  color: #484848;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.25s;
}

.prod-ind-overview p {
  color: #484848;
  font-size: 16px;
  margin: 15px 0 30px;
  line-height: 1.6;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  /* animation-delay: 1.5s; */
}

.prod-sample-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 1rem 0;
}

.prod-ind-big-card {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 9px;
}

.prod-ind-big-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-height: 100%;
  gap: 10%;
  padding: 2px 16px;
}

.border-purple {
  border: 1px solid #1890ff;
}

.prod-ind-zoomimg {
  /* max-width: 630px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  height: 100%;
  padding: 8% 4% 0;
}

.prod-sample-zoomimg {
  max-width: 100%;
  /* max-height: 100%; */
  /* object-fit: cover; */
  margin: 1rem 0;
  /* width: 1222px; */
  /* max-width: none; */
  /* margin-top: 60px; */
  /* margin-left: -155px; */
  position: relative;
  display: inline-block;
  vertical-align: middle;
  will-change: transform;
}

.full-row {
  grid-column: 1 / -1;
}

.lowpadd {
  padding: 0 4% 0;
}

.topbotpadding {
  padding: 4rem 0;
}

/* .prod-img {
  position: relative;
  right: -7%;
  width: 450px;
  height: 450px;
  transform: rotate(45deg);
} */

/* .prod-img .rhombus {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #eaeaea;
  border: 25px solid #667ba3;
  box-shadow: -15px 15px 15px rgb(0, 0, 0, 0.2);
  opacity: 0;
  animation: zoomOut 1s ease forwards;
  animation-delay: 1.75s;
} */

/* .prod-img .rhombus img {
  position: absolute;
  top: 110px;
  left: -250px;
  max-width: 750px;
  transform: rotate(-45deg);
  opacity: 0;
  animation: boxImage 1s ease forwards;
  animation-delay: 2s;
} */

/* .prod .rhombus2 {
  position: absolute;
  top: -25%;
  right: -25%;
  width: 700px;
  height: 700px;
  background: #667ba3;
  transform: rotate(45deg);
  z-index: -1;
  check why this is making box dissappear
  opacity: 0;
  animation: rhombus2 1s ease forwards;
  animation-delay: 1.75s;
} */

@keyframes slideBottom {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes rhombus2 {
  0% {
    right: -40px;
    opacity: 0;
  }

  100% {
    right: -25px;
    opacity: 1;
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes boxImage {
  0% {
    transform: translateX(300px, -300px) scale(0) rotate(-45deg);
    opacity: 0;
  }

  100% {
    transform: translateX(0, 0) scale(1) rotate(-45deg);
    opacity: 1;
  }
}

@media only screen and (max-width:1030px) {
  .prod-ind-content {
    padding: 10rem 4% 0;
  }

  .prod-ind-content h1 {
    font-size: 40px;
  }

  .prod-points p {
    font-size: 14px;
    margin: 15px 0 30px;
  }

  .prod-ind-sub {
    font-size: 18px;
    font-weight: 700;
    color: #1890ff;
    margin: 15px 0 30px;
  }

  .prod-btn {
    font-size: 14px;
    line-height: 1.4;
  }

  .p-btn {
    font-size: 16px !important;
  }

  .prod-ind-features h1 {
    font-size: 40px;
  }

  .prod-ind-features h3 {
    font-size: 20px;
  }

  .prod-ind-features3 h1 {
    font-size: 40px;
  }

  .prod-ind-features3 h3 {
    font-size: 20px;
  }

  .prod-card-container {
    column-gap: 4rem;
    row-gap: 4rem;
    margin: 1rem;
  }

  .prod-card-container2 {
    column-gap: 4rem;
    row-gap: 4rem;
    margin: 1rem;
  }

  .prod-card-container3 {
    column-gap: 4rem;
    row-gap: 4rem;
    margin: 2rem;
  }

  .prod-ind-news h1 {
    font-size: 40px;
  }

  .prod-ind-proph h1 {
    font-size: 40px;
  }

  .prod-ind-cloud h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }

  .prod-ind-desc h1 {
    font-size: 40px;
  }

  .prod-ind-overview h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width:780px) {
  .prod-ind-content {
    padding: 7rem 4% 0;
  }

  .prod-ind-content h1 {
    font-size: 30px;
  }

  .prod-points p {
    font-size: 12px;
    margin: 15px 0 30px;
  }

  .prod-ind-sub {
    font-size: 16px;
    font-weight: 500;
    color: #1890ff;
    margin: 15px 0 30px;
  }

  .prod-btn {
    font-size: 12px;
    line-height: 1.2;
  }

  .p-btn {
    font-size: 14px !important;
  }

  .prod-ind-features h1 {
    font-size: 30px;
  }

  .prod-ind-features3 h1 {
    font-size: 30px;
  }

  .prod-card-container {
    column-gap: 3rem;
    row-gap: 3rem;
    margin: 1rem;
  }

  .prod-card-container2 {
    column-gap: 3rem;
    row-gap: 3rem;
    margin: 1rem;
  }

  .prod-ind-features h3 {
    font-size: 15px;
  }

  .prod-ind-features3 h3 {
    font-size: 15px;
  }

  .prod-ind-news h1 {
    font-size: 30px;
  }

  .prod-ind-proph h1 {
    font-size: 30px;
  }

  .prod-ind-cloud h1 {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .prod-ind-desc h1 {
    font-size: 30px;
  }

  .prod-ind-overview h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width:385px) {
  .prod-ind-content {
    padding: 4rem 4% 0;
    display: block;
  }

  .prod-ind-content h1 {
    font-size: 20px;
  }

  .prod-points p {
    font-size: 10px;
    margin: 10px 0 15px;
  }

  .prod-ind-sub {
    font-size: 14px;
    font-weight: 500;
    color: #1890ff;
    margin: 15px 0 30px;
  }

  .prod-btn {
    font-size: 10px;
    line-height: 1;
  }

  .p-btn {
    font-size: 12px !important;
  }

  .displayN {
    display: none;
  }

  .prod-ind-features h1 {
    font-size: 20px;
  }

  .prod-ind-features3 h1 {
    font-size: 20px;
  }

  .prod-card-container {
    column-gap: 1rem;
    row-gap: 1rem;
    margin: 1rem;
  }

  .prod-card-container2 {
    column-gap: 1rem;
    row-gap: 1rem;
    margin: 1rem;
  }

  .prod-ind-features h3 {
    font-size: 12px;
  }

  .prod-ind-features3 h3 {
    font-size: 12px;
  }

  .prod-ind-news {
    padding: 4% 2% 0;
    display: block;
  }

  .prod-ind-news h1 {
    font-size: 20px;
  }

  .prod-ind-proph {
    padding: 4% 2% 0;
    display: block;
  }

  .prod-ind-proph h1 {
    font-size: 20px;
  }

  .prod-ind-cloud {
    padding: 4% 2% 0;
    display: block;
    margin-bottom: 10px;
  }

  .prod-ind-cloud h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .prod-ind-desc {
    padding: 4% 2% 0;
    display: block;
  }

  .prod-ind-desc h1 {
    font-size: 20px;
  }

  .prod-ind-overview h1 {
    font-size: 20px;
  }

  .prod-ind-big-card {
    flex-direction: column;
  }
}

/* `````````````````````````` */
/* Featured In */
/* ````````````````````````` */
.section-featured {
  padding: 3.8rem 0 3.8rem 0;
}

.feat-container {
  max-width: 100rem;
  margin: 0 auto;
  padding: 0 2.2rem;
}

.feat-heading-featured-in {
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2rem;
  color: #888888;
}

.feat-logos {
  display: flex;
  justify-content: space-around;
}

.feat-logos img {
  height: 2.2rem;
  filter: brightness(0);
  opacity: 50%;
}

@media only screen and (max-width: 1030px) {
  .feat-heading-featured-in {
    font-size: 0.75rem;
    letter-spacing: 0.5px;
  }

  .feat-logos img {
    height: 1.75rem;
  }
}

@media only screen and (max-width:780px) {
  .section-featured {
    padding: 2.4rem 0 2.4rem 0;
  }

  .feat-heading-featured-in {
    font-size: 0.65rem;
    letter-spacing: 0.5px;
    margin-bottom: 1rem;
  }

  .feat-logos img {
    height: 1.25rem;
  }
}

@media only screen and (max-width:385px) {
  .section-featured {
    padding: 1.4rem 0 1.4rem 0;
  }

  .feat-logos {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .feat-heading-featured-in {
    font-size: 0.55rem;
    letter-spacing: 0.5px;
    margin-bottom: 1rem;
  }

  .feat-logos img {
    height: 1rem;
  }
}